<template>
    <div style="display: flex;justify-content: space-between;flex-direction: column;height: 100vh;">
        <div style="flex: 1">
            <keyboard ref="child" @confirmBtnFn="confirmBtnFn" :licensePlate="plate_number"/>
            <van-field
                    readonly
                    clickable
                    name="area"
                    :value="value"
                    label="地区选择"
                    placeholder="点击选择省市"
                    input-align="right"
                    @click="showArea = true"
            />
            <van-popup v-model="showArea" position="bottom">
                <van-area
                        round
                        :columns-num="2"
                        :area-list="areaList"
                        @confirm="onConfirm"
                        @cancel="showArea = false"
                />
            </van-popup>
            <van-cell title="选择车场" :value="plateName"
                      :to="{name:'ParkingLot', query: {cityId:cityId,carNumber:plate_number,value:value}}"></van-cell>
            <div style="display: flex;justify-content: center;align-items: center;">
                <van-button round block @click="submit">新增</van-button>
            </div>
        </div>
        <div class="advert-image">
            <div style="width: 100%;">
                <van-swipe :autoplay="3000">
                    <van-swipe-item v-for="(image, index) in adImg" :key="index">
                        <img class="box-img" v-lazy="image.url" @click="handleClick"/>
                    </van-swipe-item>
                </van-swipe>
            </div>
        </div>
    </div>
</template>

<script>
    import {Form, Field, Button, Area, Popup, Cell, Toast, Swipe, SwipeItem} from 'vant';
    import Vue from 'vue';
    import {Lazyload} from 'vant';

    Vue.use(Lazyload);
    import {areaList} from '@vant/area-data'
    import bus from '../directives/bus'
    import Keyboard from '../components/Numkey'
    import {bindingCar, getAdvertisement} from "../api/interface"

    export default {
        name: "newly",
        components: {
            [Button.name]: Button,
            [Form.name]: Form,
            [Field.name]: Field,
            [Area.name]: Area,
            [Popup.name]: Popup,
            [Cell.name]: Cell,
            [Swipe.name]: Swipe,
            [SwipeItem.name]: SwipeItem,
            Keyboard
        },
        data() {
            return {
                value: '',
                showArea: false,
                areaList: areaList,
                plate_number: '',   //车牌号
                first: '',
                numArr: [],
                plateName: '',
                id: "",
                str: "",
                cityId: "",
                adImg: [],
                targeType: "",
                target: ""
            };
        },
        activated() {
            bus.$on('parking', function (id, name, carNumber, value) {
                this.plateName = name
                this.id = id
                this.plate_number = carNumber
                this.value = value
                console.log(carNumber, value)
                this.advertisement();
            }.bind(this));
        },
        mounted() {
        },
        methods: {
            advertisement() {
                let param = {
                    parkingId: this.id,
                    location: 4
                }
                getAdvertisement(param).then(res => {
                    if (res.data.code == 'SUCCESS') {
                        this.adImg = res.data.data.materias;
                        this.targeType = res.data.data.targeType;
                        this.target = res.data.data.target;
                    }
                })
            },
            handleClick() {
                if (this.targeType == 0) {
                    window.location.href = this.target;
                }
            },
            confirmBtnFn(val) {
                var result = val.replace(/(^\s+)|(\s+$)/g, "");
                this.plate_number = result.replace(/\s/g, "");
            },
            onConfirm(values) {
                this.$refs.child.confirmBtnFn();
                this.value = values.filter((item) => !!item).map((item) => item.name).join('/');
                this.cityId = values[1].name;
                this.showArea = false;
            },
            submit() {
                this.$refs.child.confirmBtnFn();
                let param = {
                    carNumber: this.plate_number,
                    parkingId: this.id,
                }
                bindingCar(param).then((res) => {
                    this.plateName = "";
                    this.id = "";
                    this.plate_number = "";
                    this.value = "";
                    if (res.data.code == 'SUCCESS') {
                        this.$router.push('Index')
                    } else {
                        Toast(res.data.message)
                    }
                })

            }

        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            // console.log(to.meta,'beforeRouteEnter')
            document.querySelector('body').setAttribute('style', 'background-color:#efefef');
            next();
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '');
            console.log(to.meta)
            if (to.name === 'parkingLot') {
                to.meta.keepAlive = true;
            } else {
                this.plateName = "";
                this.id = "";
                this.plate_number = "";
                this.value = "";
                to.meta.keepAlive = false;
            }
            next();
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .demo-class /deep/ input {
        border: none;
        outline: none;
        height: 30px;
        font-size: 16px;
        letter-spacing: 2px;
        padding: 0 5px;
        text-align: center;
        margin: 0 auto 0.5rem;
        width: 350px;
        height: 43px;
        box-shadow: 0px 6px 8px 0px rgba(96, 100, 112, 0.1);
    }

    /deep/ .van-cell--clickable {
        //border-radius: 0.4rem;
        margin: 0 auto 20px !important;
        width: 355px !important;
        box-shadow: 0px 6px 8px 0px rgba(96, 100, 112, 0.1) !important;
    }

    /deep/ .van-cell {
        margin: 0 auto 20px !important;
        width: 355px !important;
        box-shadow: 0px 6px 8px 0px rgba(96, 100, 112, 0.1) !important;
    }

    /deep/ .van-cell__title {
        text-align: left !important;
    }

    /deep/ .van-field__control {
        text-align: right !important;
    }

    .license-plate {
        .keyboard-wrap {
            bottom: 0;
        }
    }

    .van-button {
        width: 300px;
        background: linear-gradient(-30deg, #EC7955, #DC5040);
        border-radius: 27px;
        border: none;
        font-weight: 500;
        color: #FFFFFF;
    }

    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 100px;
        text-align: center;
    }

    .advert-image {
        flex: 1;
        display: flex;
        align-items: flex-end;
        margin-bottom: 30px;
    }

    .box-img {
        max-width: 100%;
        max-height: 300px;
    }
</style>
